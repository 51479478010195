var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "title": "Form Sumber Kas Pengadaan",
      "id": "kas-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h1', {
    staticClass: "text-danger text-center mb-2 mt-1"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPengadaan)))])]), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.akunOptions
    },
    model: {
      value: _vm.akun,
      callback: function callback($$v) {
        _vm.akun = $$v;
      },
      expression: "akun"
    }
  })], 1), _vm.isKas ? _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.akunOptions,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.id_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "id_kas", $$v);
      },
      expression: "form.id_kas"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }