var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "approve-rincian-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.rincians
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.asset ? _c('span', [_vm._v(" " + _vm._s(item.asset.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" Rp " + _vm._s(item.asset ? _vm.formatRupiah(item.asset.harga_dasar) : 0) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(harga)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(parseInt(item.harga))) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.status == 2 ? _c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              item.status = _vm.toggleStatus(item.status);
            }
          }
        }) : _c('feather-icon', {
          staticClass: "text-success cursor-pointer",
          attrs: {
            "icon": "CheckIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              item.status = _vm.toggleStatus(item.status);
            }
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }